<template>
  <section class="container h-screen mx-auto flex">

    <div v-if="stage === 'confirmation'" class="flex-grow flex flex-col max-w-xl justify-center p-6 overflow-hidden">
      <h1 class="text-4xl font-bold">Property Added</h1>
      <div class="mt-6">
        <strong>{{name}}</strong> has been successfully added to your account.
      </div>
      <div class="mt-12">
        <div class="text-right">
          <router-link to="/properties"  class="mt-4 py-4 px-8 font-semibold rounded-lg shadow-md text-white border bg-gray-800 hover:bg-gray-900 transition duration-200 ease-in-out transform shadow-md focus:shadow-xl hover:shadow-xl outline-none">
            Return to overview
          </router-link>
        </div>
      </div>
    </div>

    <div v-else-if="stage === 'preview'" class="flex-grow flex flex-col max-w-xl justify-center p-6 overflow-hidden">
      <h1 class="text-4xl font-bold">Confirm</h1>
      <div class="mt-6">

        <strong>{{name}}</strong><br/>
        {{formatted_address}}<br/>
        {{international_phone_number}}<br/>
        <a v-if="website" :href="website" target="_blank" class="underline">{{website}}</a>

        <div class="text-right">
          <button @click="stage = ''" class="mr-1 mt-4 py-4 px-8 font-semibold rounded-lg shadow-md text-white border bg-gray-400 hover:bg-gray-500 transition duration-200 ease-in-out transform shadow-md focus:shadow-xl hover:shadow-xl outline-none">
            Back
          </button>
          <button @click="handleAddProperty" class="mt-4 py-4 px-8 font-semibold rounded-lg shadow-md text-white border bg-gray-800 hover:bg-gray-900 transition duration-200 ease-in-out transform shadow-md focus:shadow-xl hover:shadow-xl outline-none">
            Add
          </button>
        </div>

      </div>
    </div>

    <div v-else class="flex-grow flex flex-col max-w-xl justify-center p-6 overflow-hidden">
      <h1 class="text-4xl font-bold">New Property</h1>
      <p class="mt-6">
        Start by typing your property name below.<br/>
        <em>We use Google Maps to retrieve your property details.</em>
      </p>

      <form @submit="handleAddProperty" class="my-4">
        <input type="text" id="autocomplete" placeholder="Property name..." class="w-full px-6 py-4 text-xl rounded-lg border-0 transition duration-200 ease-in-out transform shadow-md focus:shadow-xl hover:shadow-xl outline-none"/>

        <div class="grid grid-cols-2 gap-x-8">
          <div>
            <router-link to="/dashboard" class="inline-block mt-4 py-4 font-semibold opacity-50 hover:opacity-100 transition duration-200 ease-in-out transform outline-none">
              Cancel
            </router-link>
          </div>
          <div class="text-right">
            <button @click="preview()" :disabled="!place_id" type="submit" :class="!place_id ? 'invisible' : ''" class="mx-auto mt-4 py-4 px-8 font-semibold rounded-lg shadow-md text-white border bg-gray-800 hover:bg-gray-900 transition duration-200 ease-in-out transform shadow-md focus:shadow-xl hover:shadow-xl outline-none">
              Continue
            </button>
          </div>
        </div>

      </form>
    </div>

  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import gmapsInit from '../utils/gmaps'

export default {
  name: 'AddProperty',
  data () {
    return {
      id: '',
      name: '',
      street_number: '',
      subpremise: '',
      street: '',
      postal_code: '',
      city: '',
      neighborhood: '',
      areaShort: '',
      areaLong: '',
      stateCode: '',
      stateName: '',
      countryCode: '',
      countryName: '',
      international_phone_number: '',
      latitude: '',
      longitude: '',
      place_id: '',
      plus_code: '',
      types: '',
      website: '',
      formatted_address: '',
      address: {},
      stage: ''
    }
  },
  async mounted () {
    try {
      const google = await gmapsInit()
      const input = document.getElementById('autocomplete')
      const options = {
        types: ['establishment']
      }
      const autocomplete = new google.maps.places.Autocomplete(input, options)
      google.maps.event.addListener(autocomplete, 'place_changed', function () {
        var place = autocomplete.getPlace()
        this.address = place
        this.parseAddress()
      }.bind(this))
    } catch (e) {
      console.log(e)
    }
  },
  computed: mapGetters(['properties', 'getAccount']),
  methods: {
    ...mapActions(['addProperty']),
    handleAddProperty (e) {
      e.preventDefault()
      const userId = this.getAccount.$id
      if (!userId || !this.place_id) {
        console.log('User id is null or no property/place_id')
      } else {
        let street = this.street + ' ' + this.street_number + ' ' + this.subpremise
        street = street.replace(/\s+/g, ' ').replace(/^\s+|\s+$/gm, '')
        const data = {
          createdAt: Date.now(),
          updatedAt: Date.now(),
          name: this.name,
          plus_code: this.plus_code,
          google_place_id: this.place_id,
          formatted_address: this.formatted_address,
          street: street,
          postal_code: this.postal_code,
          city: this.city,
          neighborhood: this.neighborhood,
          region: this.areaLong,
          state_name: this.stateName,
          state_code: this.stateCode,
          country_name: this.countryName,
          country_code: this.countryCode,
          latitude: this.latitude,
          longitude: this.longitude,
          website: this.website,
          phone_number: this.international_phone_number,
          belongsTo: userId
        }
        console.log(data)
        const read = ['role:all']
        const write = [`user:${userId}`]
        const result = this.addProperty({
          data,
          read: read,
          write: write
        })

        result.then(
          function (value) {
            if (value) {
              this.stage = 'confirmation'
            }
          }.bind(this)
        )
      }
    },
    preview () {
      this.stage = 'preview'
    },
    parseAddress () {
      this.name = ''
      this.street_number = ''
      this.subpremise = ''
      this.street = ''
      this.postal_code = ''
      this.areaShort = ''
      this.areaLong = ''
      this.neighborhood = ''
      this.stateCode = ''
      this.stateName = ''
      this.city = ''
      this.countryCode = ''
      this.countryName = ''
      this.international_phone_number = ''
      this.latitude = ''
      this.longitude = ''
      this.place_id = ''
      this.plus_code = ''
      this.types = ''
      this.website = ''
      this.vicinity = ''

      var address = this.address
      var components = address.address_components
      this.name = address.name
      this.place_id = address.place_id
      this.plus_code = address.plus_code.global_code
      this.types = address.types
      this.formatted_address = address.formatted_address
      this.latitude = address.geometry.location.lat()
      this.longitude = address.geometry.location.lng()
      if (address.website) {
        var url = address.website
        if (url && url.includes('?')) {
          url = url.slice(0, url.indexOf('?'))
        }
        this.website = url
      }

      for (var i = 0, component; (component = components[i]); i++) {
        for (var j = 0; j < component.types.length; j++) {
          if (component.types[j] === 'street_number') {
            this.street_number = component.long_name
          }
          if (component.types[j] === 'subpremise') {
            this.subpremise = component.long_name
          }
          if (component.types[j] === 'route') {
            this.street = component.long_name
          }
          if (component.types[j] === 'postal_town') {
            this.city = component.long_name
          } else if (component.types[j] === 'locality') {
            this.city = component.long_name
          }
          if (component.types[j] === 'administrative_area_level_2') {
            this.areaShort = component.short_name
            this.areaLong = component.long_name
          }
          if (component.types[j] === 'administrative_area_level_1') {
            this.stateCode = component.short_name
            this.stateName = component.long_name
          }
          if (component.types[j] === 'neighborhood') {
            this.neighborhood = component.short_name
            this.neighborhood = component.long_name
          }

          if (component.types[j] === 'sublocality_level_1') {
            this.neighborhood = component.long_name
          }
          if (component.types[j] === 'country') {
            this.countryCode = component.short_name
            this.countryName = component.long_name
          }
          if (component.types[j] === 'postal_code') {
            this.postal_code = component.long_name
          }
        }
        this.international_phone_number = address.international_phone_number
        this.location = address.geometry.location
      }
    }
  }
}
</script>
